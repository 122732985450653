<template>
  <div class="container">
    <!--选择酒店 -->
    <el-dialog
      title="选择酒店"
      :visible.sync="dialogVisible"
      width="45%"
      @close="closeDialog"
      :close-on-click-modal="false"
    >
      <el-radio-group v-model="hotel">
        <el-radio-button
          v-for="item in hotelList"
          :key="item.id"
          :value="item.id"
          :label="item.id"
          >{{ item.hotelName }}</el-radio-button
        >
      </el-radio-group>

      <span slot="footer" class="dialog-footer">
        <el-button class="anniu" type="primary" @click="choseHotel"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { postRequest } from "@/api/api.js";
export default {
  data() {
    return {
      dialogVisible: true,
      hotelList: [],
      selectHotel: {},
      user: {},
      hotel: null,
    };
  },
  components: {},
  created() {
    this.user = JSON.parse(window.localStorage.getItem("user"));
    this.hotelList = JSON.parse(window.localStorage.getItem("hotelList"));
  },
  methods: {
    // 选择酒店
    choseHotel() {
      if (this.hotel == null) {
        this.$message({
          duration: 5000,
          message: "请选择酒店！",
          center: true,
        });
        return;
      }
      this.hotelList.forEach((item) => {
        if (item.id == this.hotel) {
          this.selectHotel.hotelId = item.id;
          this.selectHotel.hotelName = item.hotelName;
        }
      });
      this.selectHotel.userCode = this.user.userCode;
      this.selectHotel.userName = this.user.userName;
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("/selectHotel", this.selectHotel).then((res) => {
        if (res.status == 200) {
          localStorage.setItem("hotelInfor", JSON.stringify(res.data));
          localStorage.setItem("findPage", 0);
          this.$store.state.roomList = [];
          this.getRoom();
          if (this.$store.state.roomList.length > 0) {
            this.$nextTick(() => {
              loadingInstance.close();
            });
          }
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "error",
            hasClose: true,
          }); //如果无法获取这一条数据，就报错
        }
      });
    },
    closeDialog() {
      if (this.hotel == null) {
        this.$message({
          duration: 5000,
          message: "请选择酒店！",
          center: true,
        });
        this.dialogVisible = true;
        return;
      }
    },
    getRoom() {
      postRequest("/selectAllRoomStatus", {}).then((res) => {
        if (res.status == 200) {
          res.data.forEach((item) => {
            item.choose = false;
            console.log(444, item);
            let allFlag = 0;
            let list = [];
            item.serviceStatusList.forEach((item2) => {
              console.log(item2.valueCode);
              if (item2.valueCode == "TurnOn") {
                list.push(item2);
              }
            });
            if (list.length > 0) {
              allFlag = 1;
            } else {
              allFlag = 0;
            }
            item.Flag = allFlag;
          });
          this.$store.state.roomList = res.data;
          this.$store.state.roomTableList = [];
          this.$router.push("/roomControl");
          //
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "warning",
          });
        }
      });
    },
  },
};
</script>
<style scoped>
>>> .el-icon-close {
  display: none;
}
</style>
